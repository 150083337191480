var $window, $document, $body;

var moduleApp = {
  'init': function() {
    this.initGlobals();
    this.initGlobalsEvents();
    this.executeModules();
  },
  'initGlobals': function() {
    $window = $(window);
    $document = $(document);
    $body = $('#body');
    $window.on('load', function() {
      $window.trigger('resize');
    });
  },
  'initGlobalsEvents': function() {

    var methods = {
      'init': function() {
        methods.getInitialEvents('click');
      },
      'getInitialEvents': function(thisEvent) {
        $document.on(thisEvent, '[data-g' + thisEvent + ']', function(e) {
          e.preventDefault();
          var $this = $(this);
          var thisAction = $this.attr('data-g' + thisEvent);
          var thisValue = $this.val() || $this.data().value;
          if (actions[thisAction]) {
            actions[thisAction]($this, thisValue);
          } else {
            console.log('Event: ' + thisEvent + ', Action:"' + thisAction + '" - not defined');
          }
        });
      },
      'getTarget': function(thisTarget) {
        return $('[data-target="' + thisTarget + '"]');
      }
    };

    var actions = {

      'scroll-to-anchor': function($this) {
        var target = $this.attr('href');
        if (target.length) {
          $('html, body').stop().animate({
            scrollTop: $(target).offset().top - 70
          }, 700);
        }
      },
      'toggleGlobalMenu': function($this) {
        var $burger = $this.find('.hamburger');
        var $menu = $('#globalMenu');
        $burger.toggleClass('active');
        $menu.toggleClass('active');
        $body.toggleClass('menu-open-state');
        $menu.stop().animate({ scrollTop: 0}, 500);
      }
    };

    methods.init();
  },
  'executeModules': function() {
    $('[data-is]').each(function(i, thisModule) {
      var $thisModule = $(thisModule);
      var thisModuleName = $thisModule.attr('data-is');
      if (moduleApp[thisModuleName]) {
        console.log('Auto start: `' + thisModuleName + '`.');
        moduleApp[thisModuleName]($thisModule);
      } else {
        console.log('Module: `' + thisModuleName + '` is not defined.');
      }
    });
  },
  'hero-slider': function($thisModule) {
    var swiper,
        $parent = $thisModule.find('.swiper-container'),
        $slides = $parent.find('.swiper-slide'),
        $pager = $thisModule.find('.js_pager');
    if ($slides.length < 2) {
      $pager.hide();
    }
    swiper = new Swiper($parent, {
      loop: true,
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      effect: 'fade',
      speed: 1000,
      fadeEffect: {
        crossFade: true
      },
      pagination: {
        el: $pager,
        type: 'bullets',
        clickable: true
      }
    });
  },
  'p-slider': function($thisModule) {
    var swiper,
        $parent = $thisModule.find('.swiper-container'),
        $slides = $parent.find('.swiper-slide'),
        $pager = $thisModule.find('.js_pager');

    swiper = new Swiper($parent, {
      slidesPerView: 8,
      spaceBetween: 0,
      loopedSlides:8,
      slidesPerGroup: 2,
      centerInsufficientSlides: true,
      loop: true,
      speed: 1000,
      pagination: {
        el: $pager,
        type: 'bullets',
        clickable: true
      },
      breakpoints: {
        320: {
          slidesPerView: 'auto',
          slidesPerGroup: 1,
          speed: 300,
          loopedSlides: null,
          centeredSlides:true
        }
      }
    });
  },
  'tabs-nav': function($thisModule, inputConfig){
    var defaultConfig = {
      'speed':'300',
      'initAttr':'data-tab-body',
      'initAttrType':'.',
      'onBeforeChange':false,
      'onAfterChange':false,

      // private config

      'opacityDuration':'150',
      'opacityDelay':'150',
      'wrapperClass':'.is-tabs__wrapper',
      'contentClass':'.is-tabs__tab',
      'navLinkClass':'a, .is-tabs__link'
    };

    var tabsConfig = $.extend({}, defaultConfig, inputConfig);

    var debouncer = false;
    var playStringRaw = 'transition:height '+tabsConfig.speed+'ms 0s, opacity '+(parseInt(tabsConfig.speed)+parseInt(tabsConfig.opacityDuration))+'ms '+tabsConfig.opacityDuration+'ms;';
    var playStringCSS = '-webkit-'+playStringRaw+'-moz-'+playStringRaw+'-ms-'+playStringRaw+'-o-'+playStringRaw+playStringRaw;
    var containerBox = $thisModule.attr(tabsConfig.initAttr) || false;

    var $navLinks = $thisModule.find(tabsConfig.navLinkClass);
    var $containerBox = $(tabsConfig.initAttrType + containerBox);
    var $wrapperBox = $containerBox.find(tabsConfig.wrapperClass);
    var $contentBox = $containerBox.find(tabsConfig.contentClass);

    var cH = $wrapperBox.outerHeight();
    var lastIndex = -1;

    var tabsCore = {
      'addEvent':function(){
        $navLinks.on('click', function(e){
          e.preventDefault();
          var $this = $(this);
          if (debouncer || $this.hasClass('active')) { return false; }
          debouncer = true;
          var thisIndex = $this.index();
          $navLinks.removeClass('active');
          $this.addClass('active');
          if (tabsConfig.onBeforeChange) {
            var cbReturn = tabsConfig.onBeforeChange({
              'oldIndex':lastIndex,
              'newIndex':thisIndex,
              'thisLink':$this,
              'thisContent':$contentBox.eq(thisIndex)
            });
            if (cbReturn===false) {
              debouncer = false;
              return false;
            }
          }
          $containerBox.attr('style','opacity:0;height:'+cH+'px;');
          tabsCore.changeTab(thisIndex);
        });
      },
      'changeTab':function(thisIndex){
        $contentBox.hide().eq(thisIndex).show();
        cH = $wrapperBox.outerHeight();
        $containerBox.attr('style','opacity:1;height:'+cH+'px;'+playStringCSS);
        setTimeout(function(){
          $containerBox.attr('style','height:auto;');
          if (tabsConfig.onAfterChange) {
            var cbReturn = tabsConfig.onAfterChange({
              'oldIndex':lastIndex,
              'newIndex':thisIndex,
              'thisLink':$navLinks.eq(thisIndex),
              'thisContent':$contentBox.eq(thisIndex)
            });
            if (cbReturn===false) {
              debouncer = false;
              return false;
            }
          }
          lastIndex = thisIndex;
          debouncer = false;
          $window.trigger('resize');

        }, parseInt(tabsConfig.speed)+25);
      }
    };

    // init
    tabsCore.addEvent();
    $navLinks.eq(0).addClass('active');
  }
  
};

$(document).ready(function() {
  moduleApp.init();
});